import React from "react";
import "./section.css";
import {
  applestore,
  applestoreblack,
  deliveryimage1,
  deliveryimage2,
  earningimage1,
  earningimage2,
  foodimage1,
  foodimage2,
  forwardiconimg,
  livetrackingimage,
  playstore,
  rideimage1,
  rideimage2,
  rideimage3,
} from "../../utilities/images";

function Section() {
  return (
    <>
      <div className='section'>
        <div className='section-container'>
          <div className='section-container-top'>
            <h1>Rides</h1>
            <p>
              Get where you need to go effortlessly, with a payment process
              designed to be as smooth as your ride.
            </p>
          </div>
          <div className='section-container-middle'>
            <img className='dims1' src={rideimage1} alt='' />
            <img className='dims' src={rideimage2} alt='' />
          </div>
          <div className='section-container-bottom'>
            <div className='section-container-bottom-left'>
              <div className='section-container-bottom-title'>
                <p>
                  Manage your Coins and payments easily for a seamless ride
                  experience.
                </p>
                <div className='navbar-downloadspace3'>
                  <a href='#' className='downloadlink3'>
                    <img src={applestore} alt='apple' />
                    <label>Apple store</label>
                  </a>
                  <a href='#' className='downloadlink3'>
                    <img src={playstore} alt='apple' />
                    <label>Play store</label>
                  </a>
                </div>
              </div>
            </div>
            <div className='section-container-bottom-right'>
              <div className='headerrimg'>
                <img src={rideimage3} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='section-container'>
          <div className='section-container-top'>
            <h1>Deliveries</h1>
            <p>
              Easily send your packages with fast and secure delivery options
            </p>
          </div>
          <div className='section-container-middle'>
            <img className='dims1' src={deliveryimage2} alt='' />
            <img className='dims' src={deliveryimage1} alt='' />
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='section-container'>
          <div className='section-container-top'>
            <h1>Food</h1>
            <p>
              Get where you need to go effortlessly, with a payment process
              designed to be as smooth as your ride.
            </p>
          </div>
          <div className='section-container-middle'>
            <img className='dims1' src={foodimage1} alt='' />
            <img className='dims dimss' src={foodimage2} alt='' />
          </div>
        </div>
      </div>

      <div className='sectionearn'>
        <div className='sectionearn-container'>
          <div className='sectionearn-left'>
            <div className='sectionearn-title'>
              <h1>Want to earn with 9troride?</h1>
              <p>
                Get where you need to go effortlessly, with a payment progress
                designed to be as smooth as your ride.
              </p>
              <a href='#' className='downloadlink2'>
                Apply to be a driver <img src={forwardiconimg} alt='' />
              </a>
            </div>
          </div>
          <div className='sectionearn-right'>
            <div className='headerimg'>
              <picture>
                <source srcset={earningimage2} media='(max-width: 500px)' />
                <img src={earningimage1} alt='Responsive Image' />
              </picture>
            </div>
          </div>
        </div>
      </div>
      <div className='sectionn'>
        <div className='section-container'>
          <div className='section-container-live'>
            <div className='section-container-live-left'>
              <div className='section-container-live-title'>
                <h1>Find everything easily on the app</h1>
                <p>
                  Sign up now and experience convenient rides and easy payments,
                  all in one app.
                </p>
                <div className='navbar-downloadspace2'>
                  <a href='#' className='downloadlink2'>
                    <img src={applestoreblack} alt='apple' />
                    <label>Apple store</label>
                  </a>
                  <a href='#' className='downloadlink2'>
                    <img src={playstore} alt='apple' />
                    <label>Play store</label>
                  </a>
                </div>
              </div>
            </div>
            <div className='section-container-live-right'>
              <div className='headerrimg hdimg'>
                <img src={livetrackingimage} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section;
