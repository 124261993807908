import React, { useState } from "react";
import "./navbar.css";
import {
  nitrologo,
  applestore,
  playstore,
  menuopen,
  menuclose,
} from "../../utilities/images";

function Navbar() {
  const [openMenu, setOpenMenu] = useState(true);
  return (
    <div className='navbar'>
      <div className='navbar-container'>
        <div className='navbar-logo'>
          <img src={nitrologo} alt='9troride' />
        </div>
        <div className='navbar-links'>
          <a href='#'>Ride</a>
          <a href='#'>Food</a>
          <a href='#'>Deliveries</a>
        </div>
        <div className='navbar-downloadspace'>
          <a href='#' className='downloadlink'>
            <img src={applestore} alt='apple' />
            <label>Apple store</label>
          </a>
          <a href='#' className='downloadlink'>
            <img src={playstore} alt='apple' />
            <label>Play store</label>
          </a>
        </div>
        <div className='navbar-mobile'>
          <button onClick={() => setOpenMenu(!openMenu)}>
            <img src={openMenu ? menuopen : menuclose} alt='' />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
