import React from "react";
import "./chooseride.css";
import {
  applestoreblack,
  headerimage,
  playstore,
} from "../../utilities/images";

function ChooseRide() {
  return (
    <div className='chooseride'>
      <div className='chooseride-container'>
        <div className='chooseride-left'>
          <div className='chooseride-title'>
            <h1>Quick rides made easy</h1>
            <p>
              Get where you need to go effortlessly, with a payment progress
              designed to be as smooth as your ride.
            </p>
            <div className='navbar-downloadspace2'>
              <a href='#' className='downloadlink2'>
                <img src={applestoreblack} alt='apple' />
                <label>Apple store</label>
              </a>
              <a href='#' className='downloadlink2'>
                <img src={playstore} alt='apple' />
                <label>Play store</label>
              </a>
            </div>
          </div>
        </div>
        <div className='chooseride-right'>
          <div className='headerimg'>
            <img src={headerimage} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseRide;
