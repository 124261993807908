import React from "react";
import Navbar from "../navbar/navbar";
import "./header.css";
import ChooseRide from "../chooseride/chooseride";
import { headerbg, inbusinessimg, backgroundsvg } from "../../utilities/images";

function Header() {
  return (
    <div
      className='header'
      style={{
        backgroundImage: `url(${backgroundsvg})`,
      }}
    >
      <Navbar />
      <ChooseRide />
    </div>
  );
}

export default Header;
