import duimg from "../assets/onlydriver.JPG";
import iimg from "../assets/driversandrider.JPG";
import hbg from "../assets/9trorideandrider.JPG";
import pimg from "../assets/payment.jpg";
import backgroundssvg from "../assets/bgpng.svg";
import nitroridelogo from "../assets/logo.svg";
import applogo from "../assets/applelogo.svg";
import playlogo from "../assets/playstorelogo.svg";
import headerimg from "../assets/headerimage.svg";
import applogoblack from "../assets/appleblack.svg";
import menuo from "../assets/menuopen.svg";
import menuc from "../assets/menuclose.svg";
import rideimg1 from "../assets/rideimage1.svg";
import rideimg2 from "../assets/safeamount.svg";
import rideimg3 from "../assets/coinbalanceimg.svg";
import deliveryimg1 from "../assets/deliveriesimg.svg";
import deliveryimg2 from "../assets/deliveriesimg2.svg";
import foodimg1 from "../assets/foodimg1.svg";
import foodimg2 from "../assets/foodimg2.svg";
import earningimg1 from "../assets/earning.svg";
import earningimg2 from "../assets/earningimg2.svg";
import forwardicon from "../assets/forwardicon.svg";
import livetrackingimg from "../assets/livetracking.svg";
import logowhite from "../assets/logowhite.svg";
import fimg from "../assets/f.svg";
import igimg from "../assets/ig.svg";
import inimg from "../assets/in.svg";
import ximg from "../assets/x.svg";

export const driveruserimg = duimg;
export const inbusinessimg = iimg;
export const headerbg = hbg;
export const paymentimg = pimg;
export const backgroundsvg = backgroundssvg;
export const nitrologo = nitroridelogo;
export const applestore = applogo;
export const playstore = playlogo;
export const headerimage = headerimg;
export const applestoreblack = applogoblack;
export const menuopen = menuo;
export const menuclose = menuc;
export const rideimage1 = rideimg1;
export const rideimage2 = rideimg2;
export const rideimage3 = rideimg3;
export const deliveryimage1 = deliveryimg1;
export const deliveryimage2 = deliveryimg2;
export const foodimage1 = foodimg1;
export const foodimage2 = foodimg2;
export const earningimage1 = earningimg1;
export const earningimage2 = earningimg2;
export const forwardiconimg = forwardicon;
export const livetrackingimage = livetrackingimg;
export const nitrologowhite = logowhite;
export const f = fimg;
export const ig = igimg;
export const inimage = inimg;
export const x = ximg;
