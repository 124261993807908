import React from "react";
import Header from "../../components/header/header";
import Section from "../../components/section/section";
import Footer from "../../components/footer/footer";

function Homepage() {
  return (
    <div>
      <Header />
      <Section />
      <Footer />
    </div>
  );
}

export default Homepage;
