import React from "react";
import "./footer.css";
import {
  applestore,
  f,
  ig,
  inimage,
  nitrologowhite,
  playstore,
  x,
} from "../../utilities/images";

function Footer() {
  return (
    <div className='footer'>
      <div className='footer-container'>
        <div className='footer-items'>
          <img src={nitrologowhite} alt='' />
          <div className='footer-item'>
            <a href='#'>
              <img src={x} alt='' />
            </a>
            <a href='#'>
              <img src={ig} alt='' />
            </a>
            <a href='#'>
              <img src={f} alt='' />
            </a>
            <a href='#'>
              <img src={inimage} alt='' />
            </a>
          </div>
        </div>
        <div className='footer-items'>
          <div className='footer-item'>
            <p className='footer-itemheader'>9TRO</p>
          </div>
          <div className='footer-item-link'>
            <a href='#'>Rides</a>
            <a href='#'>Food</a>
            <a href='#'>Deliveries</a>
          </div>
        </div>
        <div className='footer-items'>
          <div className='footer-item'>
            <p className='footer-itemheader'>PARTNER</p>
          </div>
          <div className='footer-item-link'>
            <a href='#'>Apply as a driver</a>
            <a href='#'>Apply as a courier</a>
          </div>
        </div>
        <div className='footer-items'>
          <div className='footer-item'>
            <p className='footer-itemheader'>COMPANY</p>
          </div>
          <div className='footer-item-link'>
            <a href='#'>About us</a>
            <a href='#'>Careers</a>
            <a href='#'>Blog</a>
            <a href='#'>Help center</a>
          </div>
        </div>
      </div>
      <div className='footer-container'>
        <p className='footer-link2'>© 9troRide 2024</p>
        <div className='footer-item-link2'>
          <a href='#'>Privacy</a>
          <a href='#'>Terms</a>
        </div>
        <div className='navbar-downloadspace4'>
          <a href='#' className='downloadlink4'>
            <img src={applestore} alt='apple' />
            <label>Apple store</label>
          </a>
          <a href='#' className='downloadlink4'>
            <img src={playstore} alt='apple' />
            <label>Play store</label>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
